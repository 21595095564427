import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import {Paho} from 'ng2-mqtt/mqttws31';
import { async } from '@angular/core/testing';

@Injectable({
    providedIn: 'root'
})
export class MqttService {

    broker: string;
    topicList:  Array<Number>;
    client: any;

    constructor(broker: string, topics:  Array<Number>) {
        this.broker = broker
        this.topicList = topics
    }

    listen() {
        return new Observable(subscriber => {
            let clientId = JSON.parse(localStorage.getItem('b2buser')).email;
            this.client = new Paho.MQTT.Client(this.broker, 8084, clientId)
            this.client.onConnectionLost = (responseObject) => {
                if (responseObject.errorCode !== 0) {
                    console.log("onConnectionLost:" + responseObject.errorMessage);
                }
            };

            this.client.onMessageArrived = (message) => {
                let data = null
                try {
                    data = JSON.parse(message.payloadString)
                } catch (error) {
                    console.error(error)
                }
                if (Boolean(data))
                    subscriber.next(data)
            };

            this.client.connect({
                onSuccess: async() => {
                    for (let i = 0; i < this.topicList.length; i++){
                       await this.client.subscribe("raw/" + this.topicList[i]);
                        console.log(this.topicList[i]);
                        
                    }
                },
                userName: "backend",
                password: "backend",
                useSSL: true
            });
        })
    }

    close() {
        // console.log("CLosing conn..")
        if (this.client.connected)
            this.client.disconnect();
    }
}
