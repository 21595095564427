import { AngularFireAuth } from "@angular/fire/auth";
import { auth, User } from 'firebase/app';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  user: User;
  constructor(public afAuth: AngularFireAuth, public _router: Router) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return new Observable(subscriber => {
      this.afAuth.authState.subscribe(user => {
        if (user) {
          this.user = user;
          subscriber.next(true)
        }
        else {
          subscriber.next(false)
          this._router.navigate(['/login'])
        }
      })
    })
  }

};