import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { error } from '@angular/compiler/src/util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'B2B-Dashboard';
  constructor(public afAuth: AngularFireAuth, public router: Router) { 
    this.afAuth.authState.subscribe(user => {
      if(user){

        // getIdToken(new?)
        // TRUE - issue new token
        // FLASE - return existing token
        // keep this false, if session invalid user will be null
        // user.getIdToken(false).then(token => {
          user.getIdToken(false).then(token => {
          localStorage.setItem("authToken", token)
        }).catch(err=> {
          console.log(err);
          
        })
      } else {
        this.router.navigate(['/login'])
        console.log("LOGIN SESSION ERROR");
        
      }
    })
  }

}