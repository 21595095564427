import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class CommonApiService {

  meter_id: any;
  constructor(private http: HttpClient) { }

  BaseUrl: any = "https://service-api.ddns.net/";
  meter_list: any = this.BaseUrl + "secure/getMetersByAccountId/";
  create_account: any = this.BaseUrl + "secure/getOrCreateAccount";
  energy_graph: any = this.BaseUrl + "secure/getLast24Hours/";
  newUser: any = "https://mobile.api-server.ddns.net/saveEnquiry?type=demo";
  hearder: any = this.BaseUrl + "secure/updateReportHeader/";
  addUser: any = this.BaseUrl + "secure/addChildUserToAccount/";
  emailToggle: any = this.BaseUrl + "secure/toggleEmailReports/";
  parameter: any = this.BaseUrl + "secure/updateSubscribedParamsByMeterId/"
  energyStatement: any = this.BaseUrl + "secure/requestHourwiseStatement/"
  meterList(account_id, page) {
    return this.http.get(`${this.meter_list}${account_id}?page=${page}&chunk=10`);
  }

  CreateAccount() {
    return this.http.get(this.create_account);
  }

  energyGraphList(meter_id) {
    return this.http.get(`${this.energy_graph}${meter_id}`);
  }

  newUserEnquiry(value) {
    return this.http.post(this.newUser, value)
  }

  customeHeader(account_id, value) {
    return this.http.post(`${this.hearder}${account_id}`, value);
  }

  addNewUser(account_id, value) {
    return this.http.post(`${this.addUser}${account_id}`, value);
  }

  toggleEmail(meter_id) {
    return this.http.get(`${this.emailToggle}${meter_id}`);
  }

  changeParameter(meter_id, value) {
    return this.http.put(`${this.parameter}${meter_id}`, value);
  }

  getEnergyStatement(meter_id, from, upto, to){
    return this.http.get(`${this.energyStatement}${meter_id}?from=${from}&upto=${upto}&to=${to}`);
  }
}
