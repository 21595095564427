import { Component, OnInit } from '@angular/core';
import { CommonApiService } from 'src/app/services/common-api.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  files: any = [];
  account: any;
  account_id: any;
  email:any;
  constructor(public commonApi: CommonApiService, private toastr: ToastrService, private _router: Router) { }

  getAccount() {
    this.commonApi.CreateAccount().pipe(first()).subscribe(res => {
      try {
        this.account = res['payload']['account'][0]
        this.account_id = this.account['_id'];
      } catch (error) {
        console.log(error);
      }

    })
  }
  ngOnInit(): void {
    this.getAccount();
    $('#chooseFile').bind('change', function () {
      var filename = $("#chooseFile").val();
      if (/^\s*$/.test(filename)) {
        $(".file-upload").removeClass('active');
        $("#noFile").text("No file chosen...");
      }
      else {
        $(".file-upload").addClass('active');
        $("#noFile").text(filename.replace("C:\\fakepath\\", ""));
      }
    });
  }


  getFile(e) {
    this.files = e.target.files[0];
  }
  deleteAttachment(index) {
    this.files.splice(index, 1)
  }

  uploadFile() {
    let formData: FormData = new FormData();
    formData.append("file", this.files);

    this.commonApi.customeHeader(this.account_id, formData).pipe(first()).subscribe(res => {
      try {
        this.toastr.success("Successfully Uploaded");
         $("#chooseFile").trigger("reset");
         this._router.navigate(['/dashboard']);

      } catch (error) {
        this.toastr.error(error['messages']);
         $("#chooseFile").trigger("reset");
      }
    }, error => {
      this.toastr.error(error['messages']);
       $("#chooseFile").trigger("reset");
    })
  }

  addUser() {
    let formData: FormData = new FormData();
    formData.append("email", this.email);

    this.commonApi.addNewUser(this.account_id, formData).pipe(first()).subscribe(res=> {
      console.log(res['message']);
      try {
        this.toastr.success("Successfully Added");
        $("#addNew").trigger("reset");
      } catch (error) {
        this.toastr.error(error['message']);
      }
    }, error=> {
      this.toastr.error("There is no user record corresponding to the provided identifier.");
      
    })
  }

}
