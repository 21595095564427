import { Component, OnInit, Input } from "@angular/core";
import Chart from "chart.js";
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts";
import { CommonApiService } from "src/app/services/common-api.service";
import { map, first, filter } from "rxjs/operators";
import { Router } from "@angular/router";
import { MqttService } from "src/app/services/mqtt.service";
import { Toast, ToastrService } from "ngx-toastr";

declare var $: any;

@Component({
  selector: "app-view-meters",
  templateUrl: "./view-meters.component.html",
  styleUrls: ["./view-meters.component.scss"],
})
export class ViewMetersComponent implements OnInit {
  public datasets: any;
  public data: any;
  public salesChart: Chart;
  public clicked: boolean = true;
  public clicked1: boolean = false;
  public clicked2: boolean = false;
  public meter: any;

  lineLabel: any = [];
  lineData: any = [];
  mqttService: any;
  liveData: any;
  params: any = [];
  ap: any;
  rp: any;
  f;
  v;
  c;
  e;
  pf: any;

  pfr = 0.5;
  pfy = 0.3;
  pfb = 0.9;

  email: any;
  toDate: any;
  fromDate: any;

  constructor(
    public commonApi: CommonApiService,
    public router: Router,
    private toastr: ToastrService
  ) {
    this.meter = this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit(): void {
    this.getChart();

    $(function () {
      $("#modalToggle").click(function () {
        $("#modal").modal({
          backdrop: "static",
        });
      });

      $("#infoContinue").click(function (e) {
        e.preventDefault();
        $(".progress-bar").css("width", "40%");
        $(".progress-bar").html("Step 2 of 5");
        $('#myTab a[href="#ads"]').tab("show");
      });

      $("#adsContinue").click(function (e) {
        e.preventDefault();
        $(".progress-bar").css("width", "60%");
        $(".progress-bar").html("Step 3 of 5");
        $('#myTab a[href="#placementPanel"]').tab("show");
      });

      $("#placementContinue").click(function (e) {
        e.preventDefault();
        $(".progress-bar").css("width", "80%");
        $(".progress-bar").html("Step 4 of 5");
        $('#myTab a[href="#schedulePanel"]').tab("show");
      });

      $("#scheduleContinue").click(function (e) {
        e.preventDefault();
        $(".progress-bar").css("width", "100%");
        $(".progress-bar").html("Step 5 of 5");
        $('#myTab a[href="#reviewPanel"]').tab("show");
      });

      $("#activate").click(function (e) {
        e.preventDefault();
        var formData = {
          campaign_name: $("#campaignName").val(),
          start_date: $("#start-date").val(),
          end_date: $("#end-date").val(),
          days: {
            sunday: $("#sunday").prop("checked"),
            monday: $("#monday").prop("checked"),
            tuesday: $("#tuesday").prop("checked"),
            wednesday: $("#wednesday").prop("checked"),
            thurday: $("#thursday").prop("checked"),
            friday: $("#friday").prop("checked"),
            saturday: $("#saturday").prop("checked"),
          },
          start_time: $("#start-time").val(),
          end_time: $("#end-time").val(),
        };
        alert(JSON.stringify(formData));
      });
    });
    let broker = "service-mqtt.ddns.net";
    if (Boolean(broker)) {
      // If broker value exist, listen to live data
      this.mqttService = new MqttService(
        broker,
        Array.of(this.meter.meter_number)
      );
      this.mqttService.listen().subscribe((data) => {
        this.liveData = data;
        console.log(this.liveData);
        // console.log(this.liveData['pf'], "PF LIVE");

        let apparent_power = Math.sqrt(
          Math.pow(this.liveData["a"], 2) + Math.pow(this.liveData["r"], 2)
        );
        let pf = (this.liveData["a"] / apparent_power)||0;
        if(pf != undefined){
          this.pf = pf
        }

        let apparent_power_r = Math.sqrt(
          Math.pow(this.liveData["ar"], 2) + Math.pow(this.liveData["rr"], 2)
        );
        let pfr = (this.liveData["ar"] / apparent_power_r)||0;
        if(pfr != undefined){
          this.pfr = pfr
        }
        let apparent_power_y = Math.sqrt(
          Math.pow(this.liveData["ay"], 2) + Math.pow(this.liveData["ry"], 2)
        );
        let pfy = (this.liveData["ay"] / apparent_power_y)||0;
        if(pfy != undefined){
          this.pfy = pfy
        }
        let apparent_power_b = Math.sqrt(
          Math.pow(this.liveData["ab"], 2) + Math.pow(this.liveData["rb"], 2)
        );
        
        let pfb = (this.liveData["ab"] / apparent_power_b)||0;
        if(pfb != undefined){
          this.pfb = pfb
        }
        console.log(this.pf, "CALCULATED PF");
      });
    }
    this.commonApi
      .energyGraphList(this.meter["meter_number"])
      .pipe(first())
      .subscribe((res) => {
        let response = res["payload"];
        response.forEach((element) => {
          this.lineLabel.push(element["hour"].substring(8, 10) + "Hr");
          this.lineData.push(element["total_energy"].toFixed(3));
          this.salesChart.update();
        });
      });

    this.datasets = [this.lineData];
    this.data = this.datasets[0];
    console.log(this.data, "GRAPH");

    parseOptions(Chart, chartOptions());

    var chartSales = document.getElementById("chart-sales");

    this.salesChart = new Chart(chartSales, {
      type: "line",
      options: chartExample1.options,
      data: {
        labels: this.lineLabel,
        datasets: [
          {
            label: "Energy",
            data: this.lineData,
          },
        ],
      },
    });
  }

  getChart() {
    var config = {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [this.pfr, 1 - this.pfr],
            backgroundColor: ["#F7464A", "#46BFBD"],
          },
          {
            data: [this.pfb, 1 - this.pfb],
            backgroundColor: ["#F7464A", "#46BFBD"],
          },
          {
            data: [this.pfy, 1 - this.pfy],
            backgroundColor: ["#F7464A", "#46BFBD"],
          },
        ],
      },
      options: {
        cutoutPercentage: 70,
        responsive: true,
      },
    };

    // var ctx = document.getElementById("chart-area");
    // var myDoughnut = new Chart(ctx, config);
  }

  public updateOptions() {
    this.salesChart.data.datasets[0].data = this.data;
    console.log(this.salesChart.data.datasets[0].data);

    this.salesChart.update();
  }

  updateParams() {
    var checkboxArray = $(".form-check-input:checked")
      .map(function () {
        return this.value;
      })
      .get();
    this.params = checkboxArray;
    console.log(this.params);
    this.commonApi
      .changeParameter(this.meter._id, { params: this.params })
      .pipe(first())
      .subscribe((res) => {
        console.log(res);
        try {
          this.toastr.success(res["payload"]["event"]["event"]);
        } catch (error) {
          this.toastr.error(error["message"]);
        }
      });
  }

  getStatement() {
    if (Date.parse(this.fromDate) == Date.parse(this.toDate)) {
      this.toastr.error("From data and To date can't be equal");
    } else {
      this.commonApi
        .getEnergyStatement(
          this.liveData.m_id,
          this.fromDate,
          this.toDate,
          this.email
        )
        .pipe(first())
        .subscribe((res) => {
          this.toastr.success("Statement send to email id");
          $("#form_statement").trigger("reset");
          $("[data-dismiss=modal]").trigger({ type: "click" });
        });
    }
  }

  ngOnDestroy() {
    // SUPER IMPORTANT TO CLOSE CONNECTION ON COMPONENT DESTROY

    if (this.mqttService) {
      this.mqttService.close();
    }
  }
}
