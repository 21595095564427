import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(public afAuth: AngularFireAuth) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        return this.afAuth.idToken.pipe(
            mergeMap((token: string) => {
                if(token){
                    request = request.clone({ headers: request.headers.set('Authorization', token) });
                }
                return next.handle(request)
            })
        )
        .pipe(
            map(
                (event: HttpEvent<any>) => {
                    // if (event instanceof HttpResponse) {
                    //     console.log('event--->>>', event);
                    // }
                    return event;
            })
        );
    }



}